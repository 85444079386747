<script lang="ts" setup>

</script>

<template>
  <div>
    <HeaderMenuNav class="nav-hero" />
    <main class="bg-white">
      <slot />
    </main>
    <LazyFooterMain hydrate-on-idle />
  </div>
</template>

<style>
</style>
